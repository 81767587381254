import { AuthenticationService } from './../../authentication/authentication.service';
import { Observable, of, EMPTY } from 'rxjs';
import { OrdersService } from './../orders.service';
import { Order } from './../models/order';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OrderListResolverService implements Resolve<Order[]> {
    orderFilterFrom: Date;

    constructor(
        private orderService: OrdersService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        let orderFilterFromPreference = this.authenticationService.getDatePreference();
        this.orderFilterFrom = orderFilterFromPreference
            ? orderFilterFromPreference
            : new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Order[]> | Observable<never> {
        return this.orderService.getOrdersFromDate(this.orderFilterFrom).pipe(
            take(1),
            mergeMap((order) => {
                if (order) {
                    return of(order);
                } else {
                    // id not found
                    this.router.navigate(['/orders']);
                    return EMPTY;
                }
            })
        );
    }
}
