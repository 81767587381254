<div [class]="(isHandset$ | async) ? 'container' : 'container--resize'" >
  <mat-card fxLayout="column" fxLayoutAlign="space-between" [class]="(isHandset$ | async) ? 'form-container' : 'form-container--resize'">

    <div fxLayoutAlign="column" [class]="(isHandset$ | async) ? 'detail-card-header' : 'detail-card-header--resize'">
      <mat-icon mat-card-avatar svgIcon="si-user"></mat-icon>
      <mat-card-title fxFlexAlign="center" style="margin: 0 16px">{{pageAction()}} Cliente</mat-card-title>
    </div>

    <mat-divider [class]="(isHandset$ | async) ? 'detail-card-divider' : 'detail-card-divider--resize'"></mat-divider>

    <form [formGroup]="customerForm" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="16px" [class]="(isHandset$ | async) ? 'detail-card-content' : 'detail-card-content--resize'">
      <span class="mat-title">Anagrafica</span>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" class="flex-fix">
        <mat-form-field fxFlex="50">
          <mat-label>Telefono</mat-label>
          <input matInput type="tel" formControlName="telephone" maxlength="10">
          <mat-error *ngIf="this.customerForm.get('telephone').invalid">{{getErrorMessage('telephone')}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email">
          <mat-error *ngIf="this.customerForm.get('email').invalid">{{getErrorMessage('email')}}</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" class="flex-fix">
        <mat-form-field fxFlex="50">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="firstName">
          <mat-error *ngIf="this.customerForm.get('firstName').invalid">{{getErrorMessage('firstName')}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <mat-label>Cognome</mat-label>
          <input matInput type="text" formControlName="lastName">
          <mat-error *ngIf="this.customerForm.get('lastName').invalid">{{getErrorMessage('lastName')}}</mat-error>
        </mat-form-field>
      </div>
      <mat-list *ngIf="this.addressesArray.length">
        <mat-list-item *ngFor="let item of addressesArray; index as addressIndex">
          <span fxLayout="row" fxLayoutAlign="center center" class="counter-avatar" matListAvatar>{{addressIndex + 1}}</span>
          <span matLine>{{item.address}}, {{item.number}}</span>
          <span matLine>{{item.city}} - {{item.province}}</span>
          <button mat-icon-button color="warn" (click)="removeAddress(addressIndex)"><mat-icon>delete</mat-icon></button>
          <button mat-icon-button color="accent" (click)="addressDialog(addressIndex)"><mat-icon>edit</mat-icon></button>
        </mat-list-item>
      </mat-list>
      <div>
        <button mat-button type="button" style="width: 100%;" (click)="addressDialog()"> <mat-icon>add</mat-icon> AGGIUNGI INDIRIZZO</button>
      </div>
      <span class="mat-title">Coupons</span>
      <mat-list *ngIf="this.couponsArray.length">
        <mat-list-item *ngFor="let item of couponsArray; index as couponIndex">
          <span fxLayout="row" fxLayoutAlign="center center" class="counter-avatar" matListAvatar>{{couponIndex + 1}}</span>
          <span matLine>Codice: {{item.code}}</span>
          <span matLine *ngIf="item.isPercentage == true">Valore: {{item.value | percent }} </span>
          <span matLine *ngIf="item.isPercentage == false">Valore: {{item.value | currency: 'EUR' }} </span>
        </mat-list-item>
      </mat-list>
    </form>

    <mat-divider [class]="(isHandset$ | async) ? 'detail-card-divider' : 'detail-card-divider--resize'"></mat-divider>

    <div fxLayout="row" fxLayoutAlign="end" [class]="(isHandset$ | async) ? 'detail-card-actions' : 'detail-card-actions--resize'">
      <button mat-flat-button color="accent" *ngIf="isEdit" style="margin-right: 8px;" (click)="redirectToOrderApp()">
        <mat-icon>attach_money</mat-icon>
        <span fxHide.lt-sm style="margin-left: 6px;">ORDINA</span>
      </button>
      <button mat-flat-button type="submit" color="primary" (click)="onSubmit()" [disabled]="!this.customerForm.valid">
        <mat-icon>done</mat-icon>
        <span fxHide.lt-sm style="margin-left: 6px;">{{pageAction() | uppercase}}</span>
      </button>
    </div>

  </mat-card>
</div>
