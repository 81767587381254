<mat-toolbar color="accent">
  <button mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
  <span>Aggiungi</span>
  <span class="space"></span>
  <button mat-button [mat-dialog-close]="data.modalForm" [disabled]="!data.modalForm.valid">CONFERMA</button>
</mat-toolbar>
<div mat-dialog-content fxLayout="column" style="padding: 16px; margin: 0;">
  <form  [formGroup]="data.modalForm" fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100"  class="flex-fix">
      <mat-form-field fxFlex="70">
        <mat-label>Via</mat-label>
        <input matInput type="text" formControlName="address" required >
      </mat-form-field>
      <mat-form-field fxFlex="30">
        <mat-label>Numero</mat-label>
        <input matInput type="text" formControlName="number" required >
      </mat-form-field>
    </div>

    <mat-form-field fxFlex="50">
      <mat-label>Provincia</mat-label>
      <select matNativeControl formControlName="province" #province (change)="nativeGetCities(province.value)">
        <option *ngFor="let province of provinces" [value]="province.name" >
          {{province.name}}
        </option>
      </select>
      <mat-error *ngIf="data.modalForm.get('province').invalid">{{getAddressErrorMessage('province')}}</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="50">
      <mat-label>Citta / Comune</mat-label>
      <select matNativeControl formControlName="city">
        <option *ngFor="let city of cities" [value]="city.name">
          {{city.name}}
        </option>
      </select>
      <mat-error *ngIf="data.modalForm.get('city').invalid">{{getAddressErrorMessage('city')}}</mat-error>
    </mat-form-field>

    <ng-template #elseBlock>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" fxFlex="100"  class="flex-fix">
        <mat-form-field fxFlex="50">
          <mat-label>Provincia</mat-label>
          <mat-select formControlName="province" (selectionChange)="getCities($event)">
            <mat-option *ngFor="let province of provinces" [value]="province.name" >
              {{province.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="data.modalForm.get('province').invalid">{{getAddressErrorMessage('province')}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <mat-label>Citta / Comune</mat-label>
          <mat-select formControlName="city">
            <mat-option *ngFor="let city of cities" [value]="city.name">
              {{city.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="data.modalForm.get('city').invalid">{{getAddressErrorMessage('city')}}</mat-error>
        </mat-form-field>
      </div>
    </ng-template>

    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100"  class="flex-fix">
      <mat-form-field fxFlex="30">
        <input type="text" matInput placeholder="CAP" formControlName="zipCode" maxlength="5" required >
        <mat-error *ngIf="data.modalForm.get('zipCode').invalid">{{getAddressErrorMessage('zipCode')}}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="70">
        <input type="text" matInput placeholder="Nome nel campanello" formControlName="intercomName">
        <mat-error *ngIf="data.modalForm.get('intercomName').invalid">{{getAddressErrorMessage('intercomName')}}</mat-error>
      </mat-form-field>
    </div>

  </form>
</div>

