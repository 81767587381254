import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomersToolbarComponent } from './customers-toolbar/customers-toolbar.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { CustomersDetailComponent } from './customers-detail/customers-detail.component';
import { CustomersListResolverService } from './customers-list/customers-list-resolver.service';
import { CustomersDetailResolverService } from './customers-detail/customers-detail-resolver.service';


const routes: Routes = [
  {
    path: 'customers',
    component: CustomersToolbarComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CustomersListComponent,
        resolve: { customers: CustomersListResolverService }
      },
      {
        path: 'add',
        component: CustomersDetailComponent
      },
      {
        path: ':id',
        component: CustomersDetailComponent,
        resolve: { customer: CustomersDetailResolverService }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
