<div fxFill fxLayout="row" fxLayoutAlign="center" style="padding: 16px;">
  <img src="https://trendservizi.com/wp-content/uploads/2018/12/logo72ppi-1.png.pagespeed.ce_.FwKyyq1Jhc-uai-516x187.png" class="trend-img">
  <div class="login-card" fxLayout="column" fxFlex=60 >
    <div fxLayout="row" style="margin-bottom: 16px;">
      <span class="login-image"></span>
      <span class="login-title">tCloud</span>
    </div>
    <form [formGroup]="loginForm" fxLayout="column" fxLayoutGap="16px" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input type="email" matInput placeholder="Username" formControlName="username" required>
        <mat-error *ngIf="loginForm.get('username').invalid">{{getErrorMessage('username')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="password" matInput placeholder="Password" formControlName="password" (keyup)="loginForm.clearValidators()" required>
        <mat-error *ngIf="loginForm.get('password').invalid">{{getErrorMessage('password')}}</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" type="submit" [disabled]="!loginForm.valid">ACCEDI</button>
      <mat-error *ngIf="loginForm.invalid">{{getLoginErrorMessage()}}</mat-error>
    </form>
  </div>
</div>

