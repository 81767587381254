import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CustomersService } from "../customers.service";
import { Customer } from "../models/customer";
import { Observable } from "rxjs";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { shareReplay, map } from "rxjs/operators";
import {
  FormBuilder,
  Validators,
  FormArray,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { CustomersAddressDialogComponent } from "../dialogs/customers-address-dialog/customers-address-dialog.component";
import { Address } from "../models/address";
import { AuthenticationService } from "../../authentication/authentication.service";
import { v4 as uuidv4 } from "uuid";
import { strict } from 'assert';
import { CustomerDiscountCode } from '../models/customer-discount-code'

@Component({
  selector: "app-customers-detail",
  templateUrl: "./customers-detail.component.html",
  styleUrls: ["./customers-detail.component.scss"],
  styles: ["::content >>> app-customers-detail {flex: 1;}"],
})
export class CustomersDetailComponent implements OnInit {
  addressesArray: Address[] = [];
  customerId = "";
  country = "it";
  isEdit = true;
  clientHasPrivateId = 'false';
  couponsArray: CustomerDiscountCode[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  customerForm = this.formBuilder.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: [ { value: "", disabled: this.isEdit }, Validators.email],
    telephone: [{ value: "", disabled: this.isEdit }, Validators.required],
    addresses: this.formBuilder.array([]),
    privacy: this.formBuilder.group({
      profiling: [false, Validators.required],
      marketing: [false, Validators.required],
      general: [false, Validators.required],
    }),
  });

  get addresses() {
    return this.customerForm.get("addresses") as FormArray;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private customersService: CustomersService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getClient();
  }

  // GETS
  getClient() {
    this.route.data.subscribe((data: any) => {
      console.log(data);

      if (data.customer) {
        const customer = data.customer.data;
        const coupons = data.customer.coupons;

        this.customerForm.patchValue(customer);
        this.customerId = customer._id;

        customer.addresses.forEach((address) => {
          this.addresses.push(this.createAddress(address));
        });

        this.addressesArray = customer.addresses;

        this.couponsArray = coupons;
      } else {
        this.setAddCustomerConfiguration();
      }
    });
  }

  // ADDRESS ARRAY METHODS

  createAddress(addressData) {
    return this.formBuilder.group({
      id: addressData.id,
      address: addressData.address,
      number: addressData.number,
      city: addressData.city,
      province: addressData.province,
      intercomName: addressData.intercomName,
      country: addressData.country,
      zipCode: addressData.zipCode,
    });
  }

  generateAddressForm() {
    return this.formBuilder.group({
      id: uuidv4(),
      address: ["", Validators.required],
      number: ["", Validators.required],
      city: ["", Validators.required],
      province: ["", Validators.required],
      zipCode: ["", Validators.required],
      intercomName: [""],
      country: "it",
    });
  }

  removeAddress(index) {
    this.addresses.removeAt(index);
    this.addressesArray = this.addresses.value;
  }

  addressDialog(addressIndex?) {
    let addressForm: FormGroup;
    let isAddressEdit = false;

    if (addressIndex >= 0) {
      addressForm = this.addresses.at(addressIndex) as FormGroup;
      isAddressEdit = true;
    } else {
      addressForm = this.generateAddressForm();
    }

    const dialogReference = this.dialog.open(CustomersAddressDialogComponent, {
      autoFocus: false,
      panelClass: "form-dialog",
      height: " 100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      data: {
        modalForm: addressForm,
        isEdit: isAddressEdit,
      },
    });

    dialogReference.afterClosed().subscribe((result) => {
      if (result) {
        if (!isAddressEdit) {
          this.addresses.push(result);
        } else {
          this.addresses.at(addressIndex).patchValue(result.value);
        }
        this.addressesArray = this.addresses.value;
      }
    });
  }

  // SUBMIT

  onSubmit() {
    // handle previous customers
    if (this.customerId) {
      this.customersService
        .putCustomer(this.customerId, this.customerForm.value)
        .subscribe((data) => {
          this.location.back();
        });
    } else {
      // handle new customers
      const passwordControl = new FormControl(this.generatePassword());
      this.customerForm.addControl("password", passwordControl);
      let email = this.customerForm.get("email").value;
      if (email === '') {
        email = uuidv4() + "@nul.tapmenu.it";
        this.customerForm.get('email').setValue(email)
      }
      this.customersService
          .postCustomer(this.customerForm.value)
          .subscribe((data) => {
            data.addresses = [];

            const currentUser = this.authService.getUser();

            if (!currentUser.hasPrivateAppCustomerDB) {
              // master application
              this.customersService
                .makeNewClientVisibleOnCustomerList(data)
                .subscribe((result) => {
                  console.log(result);
                  this.location.back();
                });
            } else {
              // go back
              this.location.back();
            }
          },
          err => {
            if (err.error == "E2002")
              this.customerForm.get('email').setErrors({ emailAlreadyUsed: true });
            else if (err.error == "E2001")
              this.customerForm.get('telephone').setErrors({ telephoneAlreadyUsed: true });
          })
    }
  }

  // REDIRECT

  redirectToOrderApp() {
    if (this.customerId) {
      const currentUser = this.authService.getUser();

      this.customersService
        .redirectToWebApp(
          this.customerId,
          currentUser.hasPrivateAppCustomerDB.toString()
        )
        .subscribe((data) => {
          let windowReference = this.authService.getWebTapAWindowReference();

          if (!windowReference) {
            windowReference = window.open(data.url, "_blank");
            this.authService.setWebTapAWindowReference(windowReference);
          } else {
            windowReference.close();
            windowReference = window.open(data.url, "_blank");
            this.authService.setWebTapAWindowReference(windowReference);
          }
        });
    }
  }

  // UTILS

  setAddCustomerConfiguration() {
    this.customerForm.get("email").enable();
    this.customerForm.get("telephone").enable();
    this.isEdit = false;
  }

  pageAction() {
    return this.isEdit ? "Modifica" : "Aggiungi";
  }

  getErrorMessage(errorField?) {
    return this.customerForm.get(errorField).hasError("required")
      ? "Questo campo è richiesto."
      : this.customerForm.get(errorField).hasError("email")
      ? "Non è un email valido."
      : this.customerForm.get(errorField).hasError("emailAlreadyUsed")
      ? "Questo email è gia stato utilizzato.."
      : this.customerForm.get(errorField).hasError("emailNotValid")
      ? "Non è un email valido."
      : this.customerForm.get(errorField).hasError("nameAlreadyUsed")
      ? "Questo nome è gia stato utilizzato."
      : this.customerForm.get(errorField).hasError("telephoneAlreadyUsed")
      ? "Questo numero di telefono è già stato utilizzato"
      : "";
  }

  generatePassword() {
    const passwordLength = 8;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";

    for (let i = 0, n = charset.length; i < passwordLength; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }

    return password;
  }
}
