import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { AddressService } from 'src/app/shared/services/address.service';


@Component({
  selector: 'app-customers-address-dialog',
  templateUrl: './customers-address-dialog.component.html',
  styleUrls: ['./customers-address-dialog.component.scss']
})
export class CustomersAddressDialogComponent implements OnInit {

  provinces: any[];
  cities: any[];
  country = 'it';

  constructor(
    public dialogRef: MatDialogRef<CustomersAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private addressService: AddressService,
  ) {}

  ngOnInit(): void {
    this.addressService.getProvinces(this.country).subscribe(result  => {
      this.provinces = result;

      const addressProvince = this.data.modalForm.get('province').value;
      const addressProvinceCode = this.provinces.find(element => element.name === addressProvince);

      if (this.data.isEdit) {
        this.addressService.getCities(this.country, addressProvinceCode.code).subscribe(citiesResult  => {
          this.cities = citiesResult;
        });
      }

    });
  }

  getProvinces() {
    this.addressService.getProvinces(this.country).subscribe(result  => {
      this.provinces = result;
    });
  }

  getCities(event) {
    const province = event.source.value !== '' ? this.provinces.find(element => element.name === event.source.value) : '';
    this.addressService.getCities(this.country, province.code).subscribe(result  => {
      this.cities = result;

    });
  }

  nativeGetCities(value) {
    const province = value !== '' ? this.provinces.find(element => element.name === value) : '';
    this.addressService.getCities(this.country, province.code).subscribe(result  => {
      this.cities = result;
    });
  }

  getAddressErrorMessage(errorField?) {
    return this.data.modalForm.get(errorField).hasError('required') ? 'Questo campo è richiesto.' : '';
  }
}
