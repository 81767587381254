import { AuthenticationService } from './../../features/authentication/authentication.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isApiCustomer = request.url.startsWith(`${environment.apiUrl}/customers`);
    // const isApiGeneralCustomer = request.url.startsWith(`${environment.apiUrl}/general-customers`);

    if (isLoggedIn && isApiUrl && !isApiCustomer /*&& !isApiGeneralCustomer*/) {
        request = request.clone({ setHeaders: { Authorization: `Bearer ${currentUser.token}` } });
    }

    return next.handle(request);
  }
}
