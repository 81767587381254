import { ActivatedRoute } from '@angular/router';
import { OrdersService } from './../orders.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { shareReplay, map } from 'rxjs/operators';

@Component({
  selector: 'app-orders-toolbar',
  templateUrl: './orders-toolbar.component.html',
  styleUrls: ['./orders-toolbar.component.scss'],
  styles: ['::content >>> app-orders-toolbar {flex: 1; display: flex; flex-direction: column}']
})
export class OrdersToolbarComponent implements OnInit {
  childView: string;
  showSearch: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches), shareReplay()
  );

  constructor(
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.manageToolbar(this.route.snapshot.firstChild.params);
    });
  }

  manageToolbar(data) {
    if (data.hasOwnProperty('id')) {
      this.showSearch = false;
    } else {
      this.showSearch = true;
    }
  }

  searchOrder(data) {
    this.ordersService.searchOrder(data);
  }
}
