import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import * as socketIo from 'socket.io-client';

const SERVER_PATH = '/socket-ordini';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  private socket: any;

  public initSocket(): void {
   this.socket = socketIo( environment.apiUrl , { path: SERVER_PATH });
  }

  public authenticate(customerCode: string): any {
    this.socket.emit('join', customerCode);
  }

  public onMessage(): Observable<any> {
    return new Observable<any>(observer => {
        this.socket.on('ordine', (data: any) => observer.next(data));
    });
  }
}
