import { AuthenticationService } from './../../features/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        // console.log('Error: ' + JSON.stringify(error))
        let errorMessage = '';
        switch (error.status) {     
          case 400:
            errorMessage = error;
            break;
          case 401:
            errorMessage = error.error.message || error.statusText;
            this.authenticationService.onLogout();
            break;
          default:
            if (error.error instanceof ErrorEvent) { // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else { // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            window.alert(errorMessage);
            break;
        }

        return throwError(errorMessage);
    }));
  }
}
