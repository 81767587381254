import { OrderListResolverService } from './orders-list/order-list-resolver.service';
import { OrderDetailResolverService } from './orders-detail/order-detail-resolver.service';
import { OrdersDetailComponent } from './orders-detail/orders-detail.component';
import { OrdersToolbarComponent } from './orders-toolbar/orders-toolbar.component';
import { AuthGuard } from './../../core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersListComponent } from './orders-list/orders-list.component';


const routes: Routes = [
  {
    path: 'orders',
    component: OrdersToolbarComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: OrdersListComponent,
        resolve: { orders: OrderListResolverService }
      },
      {
        path: ':id', component: OrdersDetailComponent,
        resolve: { order: OrderDetailResolverService }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
