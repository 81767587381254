<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #sidemenu class="sidenav" fixedInViewport="true" [fixedTopGap]="(isHandset$ | async) ? 56 : 64">
    <mat-nav-list fxLayout="column" fxFlex fxLayout="column">
      <a mat-list-item *ngFor="let item of navigationSideMenu" [routerLink]="[item.link]" routerLinkActive="active" (click)="this.sidemenu.close()">
        <mat-icon mat-list-icon>{{item.icon}}</mat-icon>
        <span matLine>{{item.label}}</span>
      </a>
      <span class="space"></span>
      <a mat-list-item (click)="onLogout()" fxFlexAlign="end">
        <mat-icon mat-list-icon>logout</mat-icon>
        <span matLine>Esci</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column" fxFill>
    <mat-toolbar color="primary" *ngIf="currentUser" [style]="(isHandset$ | async) ? 'min-height: 56px; height: 56px' : 'min-height: 64px'">
      <button type="button" *ngIf="showMenu; else elseBlock" mat-icon-button (click)="sidemenu.toggle()"> <mat-icon>menu</mat-icon> </button>
      <ng-template #elseBlock>
        <button mat-icon-button  (click)="goBack()"><mat-icon>keyboard_arrow_left</mat-icon> </button>
      </ng-template>

      <span class="header-image"></span>
      <span>{{toolbarTitle}}</span>
      <span class="space"></span>

      <button mat-button ngClass.lt-sm="mat-icon-button" [matMenuTriggerFor]="menu" fxLayout="row" fxLayoutAlign="center">
        <mat-icon svgIcon="si-user"></mat-icon>
        <span fxHide.lt-sm style="margin-left: 6px;">{{currentUser.firstName | uppercase}} {{currentUser.lastName | uppercase}}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onLogout()"><mat-icon>logout</mat-icon>ESCI</button>
      </mat-menu>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
