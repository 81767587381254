import { User } from './../../../core/models/user';
import { AuthenticationService } from './../../authentication/authentication.service';
import { SlotListDialogComponent } from './../orders-dialogs/slot-list-dialog/slot-list-dialog.component';
import { ConfirmationDialogComponent } from './../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { OrdersService } from './../orders.service';
import { Order } from '../models/order';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { timestamp, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'app-orders-detail',
    templateUrl: './orders-detail.component.html',
    styleUrls: ['./orders-detail.component.scss'],
    styles: ['::content >>> app-orders-detail {flex: 1;}'],
})
export class OrdersDetailComponent implements OnInit {
    order: Order;
    orderId = this.route.snapshot.paramMap.get('id');
    currentUser: User;

    orderStates: ['VOIDED', 'RESCHEDULED', 'CONFIRMED'];

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map((result) => result.matches),
        shareReplay()
    );

    constructor(
        private breakpointObserver: BreakpointObserver,
        private ordersService: OrdersService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private location: Location,
        private dialog: MatDialog
    ) {
        this.authenticationService.currentUser.subscribe((result) => (this.currentUser = result));
    }

    ngOnInit(): void {
        this.getOrder();
    }

    getOrder() {
        this.route.data.subscribe((data: { order: Order }) => {
            this.order = data.order;
        });
        /*
    this.ordersService.getOrder(this.orderId).subscribe(result => {
      this.order = result;
    });
*/
    }

    completeName(customer) {
        return `${customer.lastName}, ${customer.firstName}`;
    }

    mappedVariants(variants) {
        let mappedVariant = '';
        return variants.map((element) => {
            if (!element.extraProps.quantificabile) {
                if (element.quant > 0) {
                    mappedVariant = `+ ${element.descrizione}`;
                } else {
                    mappedVariant = `- ${element.descrizione}`;
                }
            } else {
                if (element.quant > 0) {
                    mappedVariant = `${element.quant} - ${element.descrizione}`;
                } else {
                    mappedVariant = `${element.quant} - ${element.descrizione}`;
                }
            }
            return mappedVariant;
        });
    }

    paymentType(paymentData) {
        let paymentType = 'indefinito';
        if (paymentData.when === 'PAGADOPO' && paymentData.how === 'NONE') {
            paymentType = 'Pagamento in Contanti al momento della consegna';
        } else if (paymentData.when === 'PAGAORA') {
            if (paymentData.how === 'SELLA') {
                paymentType = 'Pagato con carta di credito su circuito Sella';
            } else if (paymentData.how === 'BRAINTREE') {
                paymentType = 'Pagato con carta di credito su circuito BrainTree';
            } else if (paymentData.how == 'NEXI') {
                paymentType = 'Pagato con carta di credito su circuito NEXI';
            }
        }
        return paymentType;
    }

    showDeliveryMode(mode) {
        return mode === 'home' ? 'Consegna a casa' : 'Ritiro al negozio';
    }

    completeAddress(customer) {
        let address = `${customer.address}, ${customer.number} - ${customer.city} (${customer.province})`;

        if (customer.intercomName !== '') {
            address = `${address} - ${customer.intercomName}`;
        }

        return address;
    }

    changeOrderState(state) {
        switch (state) {
            case 'VOIDED':
                this.confirmationDialog(state);
                break;
            case 'CONFIRMED':
                this.confirmationDialog(state);
                break;
            case 'RESCHEDULED':
                this.periodPickingDialog(state);
                break;
        }
    }

    confirmationDialog(state) {
        const dialogMessage = state === 'VOIDED' ? `annullare quest'ordine?` : `confermare quest'ordine?`;

        const extraDialog = state === 'VOIDED'
          ? `Ricordati che l'ordine non viene automaticamente cancellato da TAVOLINUX.`
          : null;

        const dialogReference = this.dialog.open(ConfirmationDialogComponent, {
            data: { message: dialogMessage, extra: extraDialog },
        });

        dialogReference.afterClosed().subscribe((result) => {
            if (result) {
                this.ordersService.changeOrderState(this.orderId, state).subscribe(() => {
                    this.location.back();
                });
            }
        });
    }

    isSlotToday(slot) {
        const now = new Date(Date.now())
        const compareSlot = new Date(slot)
        return (now.setHours(0,0,0,0) === compareSlot.setHours(0,0,0,0))
    }

    canBeMoved(slot) {
        const now = new Date(Date.now())
        const compareSlot = new Date(slot)
        return this.isSlotToday(slot) || (now < compareSlot)
    }

    periodPickingDialog(state) {
        // get current order's slot
        const now = new Date(Date.now())
        const slotDate = new Date(this.order.deliverySchedule.slot)
        const baseDate  = (this.isSlotToday(slotDate) ?
                            (now > slotDate ? now : slotDate) :
                            new Date(slotDate.setHours(0,0,0,0)))

        this.ordersService.getSlots(baseDate).subscribe((data) => {
            const dialogReference = this.dialog.open(SlotListDialogComponent, {
                data: {
                    order: this.order,
                    slots: data,
                    ordersBySlot: this.currentUser.settings.ordersBySlot,
                },
            });

            dialogReference.afterClosed().subscribe((result) => {
                if (result) {
                    this.ordersService.changeOrderState(this.orderId, state, result).subscribe(() => {
                        this.location.back();
                    });
                }
            });
        });
    }

    setDeliveryStatus(status) {
        let statusText = '';
        switch (status) {
            case 'VOIDED':
                statusText = 'Cancellato';
                break;
            case 'CONFIRMED':
                statusText = 'Confermato';
                break;
            default:
                statusText = 'In lavorazione';
                break;
        }
        return statusText;
    }

    setStatusColor(status) {
        let statusText = '';
        switch (status) {
            case 'VOIDED':
                statusText = 'red';
                break;
            case 'CONFIRMED':
                statusText = 'green';
                break;
        }
        return statusText;
    }

    checkDisable(status, isReschedule?) {
        return status === 'CONFIRMED' || (status === 'VOIDED' && !isReschedule);
    }

    checkVariants(product) {
        return product.hasOwnProperty('varianti') && product.varianti.length;
    }

    parseToFloat(value) {
        return value != null ? parseFloat(value) : 0;
    }

    makeTotal(order: Order) {
        const total = this.parseToFloat(order.total);
        const delivery = this.parseToFloat(order.deliveryCost);
        const discount = this.parseToFloat(order.deliveryDiscount);

        return total + delivery + discount;
    }
}
