import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../customers.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-customers-toolbar',
  templateUrl: './customers-toolbar.component.html',
  styleUrls: ['./customers-toolbar.component.scss'],
  styles: ['::content >>> app-customers-toolbar {flex: 1; display: flex; flex-direction: column}']
})
export class CustomersToolbarComponent implements OnInit {

  showSearch: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches), shareReplay()
  );

  constructor(
    private ordersService: CustomersService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.manageToolbar(this.route.snapshot.firstChild);
    });
  }

  manageToolbar(data) {
    if (data.params.hasOwnProperty('id') || data.routeConfig.path === 'add') {
      this.showSearch = false;
    } else {
      this.showSearch = true;
    }
  }

  searchOrder(data) {
    this.ordersService.searchOrder(data);
  }
}
