import { User } from './../../../core/models/user';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from './../../../shared/services/socket.service';
import { AuthenticationService } from './../../authentication/authentication.service';
import { Order } from '../models/order';
import { OrdersService } from './../orders.service';
import { Component, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval } from 'rxjs';
@Component({
    selector: 'app-orders-list',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.scss'],
    styles: ['::content >>> app-orders-list {flex: 1}'],
})
export class OrdersListComponent implements OnInit {
    orders: Order[];
    ordersList: Order[];
    currentUser: User;
    ioConnection: any;
    socketMessages = [];
    showFIFO = true; // = true;
    showAllOrders = false;
    orderFilterFrom: Date; // = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000); // time is in msec
    searchTerm = '';
    innerHeight: number;
    innerWidth: number;

    constructor(
        private ordersService: OrdersService,
        private authenticationService: AuthenticationService,
        private socketService: SocketService,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar
    ) {
        this.authenticationService.currentUser.subscribe((result) => (this.currentUser = result));

        const fifoPreference = this.authenticationService.getOrderPreference();
        this.showFIFO = fifoPreference === true;

        const orderFilterFromPreference = this.authenticationService.getDatePreference();
        this.orderFilterFrom = orderFilterFromPreference
            ? orderFilterFromPreference
            : new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);

        interval(5 * 60 * 1000).subscribe(() => {
            // will execute every 30 seconds
            this.ordersService.getOrdersFromDate(this.orderFilterFrom).subscribe((result) => {
                if (this.showFIFO) {
                    result.reverse();
                }
                this.orders = result;
                this.processOrders();
            });
        });
    }

    ngOnInit(): void {
        this.innerHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
        this.getOrders();
        this.searchTermSubscribe();
        this.initIoConnection();
    }

    onResize(event) {
        this.innerHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
    }

    // WEBSOCKET

    initIoConnection() {
        this.socketService.initSocket();

        if (this.currentUser.customercode) {
            this.socketService.authenticate(this.currentUser.customercode);
        }

        this.socketService.onMessage().subscribe(() => {
            this.ordersService.getOrdersFromDate(this.orderFilterFrom).subscribe((result) => {
                result.reverse();
                this.orders = result;
                this.processOrders();
            });
            this.snackBar.open('Hai un nuovo ordine', 'OK', {
                verticalPosition: 'bottom',
                horizontalPosition: 'right',
                duration: 6000,
            });
        });
    }

    // GET AND HELPERS

    getOrders() {
        this.route.data.subscribe((data: { orders: Order[] }) => {
            data.orders.reverse();
            this.orders = data.orders;

            this.processOrders();
        });
    }

    generateArrayOfSlots(processedOrders: Array<Order>) {
        const arrayOfOrderSlots = [];

        let counter = 0;

        // compute slots arrays
        processedOrders.forEach((element, index) => {
            const isEmpty = arrayOfOrderSlots.length === 0;

            const mustChangeSlot =
                arrayOfOrderSlots.length > 0 &&
                arrayOfOrderSlots[counter].find(
                    (orderElement) =>
                        orderElement.deliverySchedule.slot !== processedOrders[index].deliverySchedule.slot
                );

            if (isEmpty) {
                arrayOfOrderSlots.push([]);
            } else if (mustChangeSlot) {
                arrayOfOrderSlots.push([]);
                counter++;
            }

            arrayOfOrderSlots[counter].push(element);
        });

        return arrayOfOrderSlots;
    }

    getSlotText(slotArray) {
        return slotArray[0].deliverySchedule.slot;
    }

    getSlotLoad(slotArray) {
        const ordersBySlot = this.currentUser.settings.ordersBySlot;
        const mapLoad = slotArray.map((element) => element.deliverySchedule.load);
        const currentLoad = mapLoad.reduce((totalValue, elementValue) => totalValue + elementValue);

        return `${currentLoad} / ${ordersBySlot}`;
    }

    // TEMPLATE HELPERS

    addMarginToFistSlot(slotArray) {
        const isFirstSlot = this.isFirstSlotOfTheDay(slotArray);

        return isFirstSlot ? 'add-margin' : '';
    }

    isFirstSlotOfTheDay(slotArray) {
        const slotHour = moment(slotArray[0].deliverySchedule.slot).format('HH:mm');
        const slotDay = moment(slotArray[0].deliverySchedule.slot).format('DD/MM');
        const firstSlot = this.ordersList.find(
            (element) => moment(element[0].deliverySchedule.slot).format('DD/MM') === slotDay
        );
        const firstSlotHour = moment(firstSlot[0].deliverySchedule.slot).format('HH:mm');
        return slotHour === firstSlotHour;
    }

    showHiddenOrders(show) {
        this.showAllOrders = show.checked;
        this.processOrders();
    }

    newerDatesFirstChecked(reverse) {
        this.showFIFO = reverse.checked;
        this.orders.reverse();
        this.processOrders();
        this.authenticationService.setOrderPreference(this.showFIFO);
    }

    changeFilterDate(event) {
        const newDate = moment(event.value);
        this.orderFilterFrom = newDate.toDate();
        this.ordersService.getOrdersFromDate(this.orderFilterFrom).subscribe((result) => {
            result.reverse();
            this.orders = result;
            this.processOrders();
            this.authenticationService.setDatePreference(this.orderFilterFrom);
        });
    }

    showDeliveryMode(mode) {
        return mode === 'home' ? 'Consegna a casa' : 'Ritiro al negozio';
    }

    setIconType(mode, status) {
        if (status === null || status === 'RESCHEDULED') {
            return mode === 'home' ? 'si-moped' : 'si-store';
        } else {
            return status === 'VOIDED' ? 'si-error' : 'si-success';
        }
    }

    setIconColor(mode, status) {
        if (status === null || status === 'RESCHEDULED') {
            return mode === 'home' ? 'row-icon blue' : 'row-icon orange';
        } else {
            return status === 'VOIDED' ? 'row-icon red' : 'row-icon green';
        }
    }

    setSlotViewColor(slotArray) {
        let color = '';
        const ordersBySlot = this.currentUser.settings.ordersBySlot;
        const mapLoad = slotArray.map((element) => element.deliverySchedule.load);
        const currentLoad = mapLoad.reduce((totalValue, elementValue) => totalValue + elementValue);

        switch (true) {
            case currentLoad <= ordersBySlot / 2:
                color = 'green-text';
                break;
            case currentLoad >= ordersBySlot:
                color = 'red-text';
                break;
        }
        return color;
    }

    setDateViewColor(slotArray) {
        let color = '';

        if (slotArray[0].deliverySchedule.slot < moment().format()) {
            color = 'red-text';
        }
        return color;
    }

    completeName(customer) {
        return `${customer.lastName}, ${customer.firstName}`;
    }

    completeAddress(customer) {
        let address = `${customer.address}, ${customer.number} - ${customer.city} (${customer.province})`;

        if (customer.intercomName !== '') {
            address = `${address} - ${customer.intercomName}`;
        }

        return address;
    }

    makeTileLine(order: Order) {
        const deliveryAddressText = order.deliveryAddress
            ? ` - ${this.completeAddress(order.deliveryMappedAddress)}`
            : '';
        const deliveryModeText = this.showDeliveryMode(order.deliveryMode);
        return `${deliveryModeText}${deliveryAddressText}`;
    }

    // SEARCH HELPERS

    searchTermSubscribe() {
        this.ordersService.searchTerm$.subscribe((term) => {
            this.searchTerm = term;
            this.processOrders();
        });
    }

    filterByClosedAndCancelled() {
        return this.showAllOrders
            ? this.orders
            : this.orders.filter(
                  (orderElement) =>
                      orderElement.deliveryStatus !== 'VOIDED' && orderElement.deliveryStatus !== 'CONFIRMED'
              );
    }

    processOrders() {
        const activeOrders = this.filterByClosedAndCancelled();

        let filteredOrders = [];
        // apply supplied filters
        if (this.searchTerm !== '') {
            const searchArray: any[] = activeOrders.filter((order) => {
                const customerCompleteName = this.completeName(order.deliveryMappedAddress);
                const customerCompleteAddress = this.completeAddress(order.deliveryMappedAddress);

                const haveMatch =
                    customerCompleteName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    order.deliveryMappedAddress.telephone.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    customerCompleteAddress.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    order.deliverySchedule.slot.toLowerCase().includes(this.searchTerm.toLowerCase());

                return haveMatch;
            });

            filteredOrders = searchArray;
        } else if (this.searchTerm === '') {
            filteredOrders = activeOrders;
        }

        this.ordersList = this.generateArrayOfSlots(filteredOrders);
    }
}
