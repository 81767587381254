import { AuthenticationService } from './../authentication.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  styles: ['::content >>> app-login {height: 100%}']
})
export class LoginComponent implements OnInit {

  homeUrl: ['/orders'];

  loginForm = this.formBuilder.group({
    username: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    if (this.authenticationService.currentUserValue) { this.router.navigate(['/orders']); }
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.authenticationService.onLogin(this.loginForm.value).subscribe(
      data => {
        this.router.navigate(['/orders']);
        this.loginForm.reset();
      },
      error => {
        this.loginForm.setErrors({ loginError: true });
      }
    );
  }

  getErrorMessage(errorField?) {
    return this.loginForm.get(errorField).hasError('required') ? 'Questo campo è richiesto.' :
           this.loginForm.get(errorField).hasError('email') ? 'Non è un email valido.' : '';
  }

  getLoginErrorMessage() {
    return this.loginForm.hasError('loginError') ? 'Username o password sbagliati' : '';
  }


}
