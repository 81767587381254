import { Observable, of, EMPTY } from 'rxjs';
import { OrdersService } from './../orders.service';
import { Order } from './../models/order';
import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailResolverService implements Resolve<Order>  {
  constructor(
    private orderService: OrdersService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Order> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.orderService.getOrder(id).pipe(
      take(1),
      mergeMap(order => {
        if (order) {
          return of(order);
        } else { // id not found
          this.router.navigate(['/orders']);
          return EMPTY;
        }
      })
    );
  }
}
