<cdk-virtual-scroll-viewport
    fxLayout="column"
    [itemSize]="200"
    (window:resize)="onResize($event)"
    [style.height.px]="this.innerHeight - 128"
    [style.width.px]="this.innerWidth"
>
    <div fxLayout="column" fxLayoutGap="16px" class="list" ngClass.gt-md="list--resize">
        <div
            fxLayout="column"
            fxLayout.gt-sm="row"
            fxLayoutAlign="start start"
            fxLayoutAlign.gt-sm="space-between center"
            fxLayoutGap="16px"
        >
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
                <span style="padding-bottom: 16px">Ordini dal</span>
                <mat-form-field appearance="fill">
                    <mat-label>Scegli la data</mat-label>
                    <input
                        matInput
                        [matDatepicker]="picker"
                        [value]="orderFilterFrom"
                        (dateChange)="changeFilterDate($event)"
                        disabled
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="24px" style="padding-left: 16px">
                <mat-slide-toggle class="order-slide" checked="false" (change)="showHiddenOrders($event)"
                    >Vedi tutti gli ordini</mat-slide-toggle
                >
                <mat-slide-toggle class="order-slide" [checked]="showFIFO" (change)="newerDatesFirstChecked($event)"
                    >Più recenti prima</mat-slide-toggle
                >
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end">
            <span class="mat-display-1" style="margin-bottom: 20px">Ordini da Processare:</span>
        </div>
        <div *ngIf="!ordersList.length" style="text-align: center">
            <p class="mat-headline" class="empty-list">Non ci sono nuovi ordini</p>
        </div>
        <mat-card *cdkVirtualFor="let ordersSlotArray of ordersList" [ngClass]="addMarginToFistSlot(ordersSlotArray)">
            <span *ngIf="isFirstSlotOfTheDay(ordersSlotArray)" class="mat-headline data-headline">{{
                getSlotText(ordersSlotArray) | date: 'dd/MM/yy'
            }}</span>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <span class="mat-headline" [ngClass]="setDateViewColor(ordersSlotArray)">{{
                    getSlotText(ordersSlotArray) | date: 'HH:mm'
                }}</span>
                <span class="mat-headline" [ngClass]="setSlotViewColor(ordersSlotArray)"
                    ><span style="color: black">Impegno: </span>{{ getSlotLoad(ordersSlotArray) }}</span
                >
            </div>

            <mat-nav-list style="padding-top: 0">
                <a mat-list-item *ngFor="let order of ordersSlotArray" [routerLink]="['/orders', order.id]">
                    <span mat-list-icon class="counter-container">#{{ order.orderCounter | number: '3.0' }}</span>
                    <mat-icon
                        mat-list-icon
                        [matBadge]="order.deliverySchedule.load"
                        [ngClass]="setIconColor(order.deliveryMode, order.deliveryStatus)"
                        [svgIcon]="setIconType(order.deliveryMode, order.deliveryStatus)"
                    ></mat-icon>
                    <div
                        *ngIf="order.isPaid && order.paymentType.when === 'PAGAORA'"
                        mat-list-icon
                        class="paid-container"
                    >
                        <mat-icon svgIcon="si-money" class="row-icon green" style="border-radius: 50%"></mat-icon>
                        <span style="line-height: 12px" class="mat-caption">Pagato</span>
                    </div>
                    <p matLine ngClass.xs="ex-small-tile" ngClass.gt-xs="small-tile" ngClass.gt-sm="medium-tile">
                        {{ completeName(order.deliveryMappedAddress) | titlecase }} - Tel.:
                        {{ order.deliveryMappedAddress.telephone | phone: 'IT' }}
                    </p>
                    <p matLine ngClass.xs="ex-small-tile" ngClass.gt-xs="small-tile" ngClass.gt-sm="medium-tile">
                        {{ makeTileLine(order) }}
                    </p>
                    <p
                        matLine
                        ngClass.xs="ex-small-tile"
                        ngClass.gt-xs="small-tile"
                        ngClass.gt-sm="medium-tile"
                        *ngIf="order.hasError"
                        class="red-text"
                    >
                        <mat-icon>error</mat-icon>
                        <span style="margin-left: 4px; vertical-align: super"
                            >Quest'ordine ha un errore di sincronizzazione.</span
                        >
                    </p>

                    <mat-icon>keyboard_arrow_right</mat-icon>
                </a>
            </mat-nav-list>
        </mat-card>
    </div>
</cdk-virtual-scroll-viewport>
