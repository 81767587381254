<div [class]="(isHandset$ | async) ? 'container' : 'container--resize'">
    <mat-card
        fxLayout="column"
        fxLayoutAlign="space-between"
        [class]="(isHandset$ | async) ? 'card-container' : 'card-container--resize'"
    >
        <mat-card-header [class]="(isHandset$ | async) ? 'detail-card-header' : 'detail-card-header--resize'">
            <mat-icon fxFlexAlign="center" style="margin-bottom: 16px" mat-card-avatar svgIcon="si-user"></mat-icon>
            <mat-card-title>{{ completeName(order.deliveryMappedAddress) | titlecase }}</mat-card-title>
            <mat-card-subtitle fxLayout="column">
                <span
                    >Tel.:
                    <a [href]="'tel:+39' + order.deliveryMappedAddress.telephone">
                        {{ order.deliveryMappedAddress.telephone | phone: 'IT' }}</a
                    ></span
                >
                <span
                    >E-mail:
                    <a [href]="'mailto:' + order.deliveryMappedAddress.email"
                        >{{ order.deliveryMappedAddress.email }}
                    </a></span
                >
            </mat-card-subtitle>
        </mat-card-header>

        <mat-divider
            [class]="(isHandset$ | async) ? 'detail-card-divider' : 'detail-card-divider--resize'"
        ></mat-divider>

        <mat-card-content
            fxLayout="column"
            fxLayoutGap="16px"
            [class]="(isHandset$ | async) ? 'detail-card-content' : 'detail-card-content--resize'"
        >
            <div *ngIf="order.hasError" class="red">
                <mat-icon>error</mat-icon>
                <span class="error-text">Quest'ordine ha un errore di sincronizzazione.</span>
            </div>
            <div
                ngClass.gt-xs="absolute-position-order"
                fxLayout="row"
                fxLayout.gt-xs="column"
                class="flex-fix"
                fxLayoutGap="8px"
                fxLayoutAlign="space-between"
            >
                <span class="mat-headline" style="margin-bottom: 0; text-align: end"
                    >Ordine #{{ order.orderCounter | number: '3.0' }}</span
                >
                <span class="mat-headline" [ngClass]="setStatusColor(order.deliveryStatus)" style="margin-bottom: 0">{{
                    setDeliveryStatus(order.deliveryStatus)
                }}</span>
            </div>
            <span class="mat-display-1">
                {{ showDeliveryMode(order.deliveryMode) }} alle {{ order.deliverySchedule.slot | date: 'HH:mm' }}</span
            >
            <div>
                <span class="mat-title" style="margin-bottom: 0"
                    >Prodotti ordinati - (Carico: {{ order.deliverySchedule.load }})</span
                >
                <mat-list style="padding-top: 0" class="mat-body">
                    <mat-list-item *ngFor="let row of order.rows" class="mat-body">
                        <h3 matLine class="mat-body">{{ row.quant }} - {{ row.prod.descrizione | titlecase }}</h3>
                        <span matLine *ngIf="checkVariants(row.prod)" style="white-space: normal">
                            Varianti: {{ mappedVariants(row.prod.varianti).join(', ') | titlecase }}
                        </span>
                    </mat-list-item>
                </mat-list>
            </div>
            <span class="mat-title">Totale prodotti: {{ order.total | currency: 'EUR' }}</span>
            <span class="mat-title" *ngIf="parseToFloat(order.deliveryCost) > 0 && order.deliveryMode == 'home'"
                >Prezzo consegna: {{ order.deliveryCost | currency: 'EUR' }}</span
            >
            <span class="mat-title" *ngIf="parseToFloat(order.deliveryDiscount) < 0"
                >Sconto: {{ order.deliveryDiscount | currency: 'EUR' }}</span
            >
            <span class="mat-display-1">Totale ordine: {{ makeTotal(order) | currency: 'EUR' }}</span>

            <p class="mat-title">
                <span *ngIf="order.isPaid && order.paymentType.when === 'PAGAORA'" class="paid-container"
                    ><mat-icon style="width: 28px" svgIcon="si-money"></mat-icon></span
                >{{ paymentType(order.paymentType) }}
            </p>

            <div *ngIf="order.deliveryMode === 'home'">
                <span class="mat-title">Indirizzo di consegna</span>
                <p class="mat-body">{{ completeAddress(order.deliveryMappedAddress) | titlecase }}</p>
            </div>
            <div *ngIf="order.note">
                <span class="mat-title">Note</span>
                <p class="mat-body">{{ order.note }}</p>
            </div>
        </mat-card-content>

        <mat-divider
            [class]="(isHandset$ | async) ? 'detail-card-divider' : 'detail-card-divider--resize'"
        ></mat-divider>

        <div
            fxLayout="row"
            fxLayoutAlign="space-between"
            [class]="(isHandset$ | async) ? 'detail-card-actions' : 'detail-card-actions--resize'"
        >
            <button
                mat-button
                style="margin-left: -16px"
                color="warn"
                (click)="changeOrderState('VOIDED')"
                [disabled]="checkDisable(order.deliveryStatus) || (order.isPaid && order.paymentType.when == 'PAGAORA')"
            >
                <mat-icon>delete</mat-icon>
                <span fxHide.lt-sm style="margin-left: 6px">CANCELLA</span>
            </button>
            <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="6px">
                <button
                    mat-flat-button
                    color="accent"
                    (click)="changeOrderState('RESCHEDULED')"
                    [disabled]="checkDisable(order.deliveryStatus, true) || !canBeMoved(order.deliverySchedule.slot)"
                >
                    <mat-icon>schedule</mat-icon>
                    <span fxHide.lt-sm style="margin-left: 6px">SPOSTA</span>
                </button>
                <button
                    mat-flat-button
                    color="primary"
                    (click)="changeOrderState('CONFIRMED')"
                    [disabled]="checkDisable(order.deliveryStatus)"
                >
                    <mat-icon>done</mat-icon>
                    <span fxHide.lt-sm style="margin-left: 6px">CONFERMA</span>
                </button>
            </div>
        </div>
    </mat-card>
</div>
