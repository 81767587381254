import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class IconService {
    icons = [
        'si-moped',
        'si-moped-s',
        'si-scooter',
        'si-scooter-s',
        'si-scooter-delivery',
        'si-scooter-delivery-s',
        'si-vespa',
        'si-house',
        'si-home',
        'si-enter',
        'si-exit',
        'si-user',
        'si-success',
        'si-error',
        'si-track',
        'si-location',
        'si-time',
        'si-user-s',
        'si-shop',
        'si-store',
        'si-money',
    ];

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    public registerIcons(): void {
        this.loadIcons(Object.values(this.icons), '../assets/icons');
    }

    private loadIcons(iconKeys: string[], iconUrl: string): void {
        iconKeys.forEach((key) => {
            this.matIconRegistry.addSvgIcon(
                key,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`)
            );
        });
    }
}
