import { map } from 'rxjs/operators';
import { Slot } from './models/slot';
import { environment } from './../../../environments/environment';
import { Order } from './models/order';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    private searchSource = new Subject<string>();
    searchTerm$ = this.searchSource.asObservable();

    constructor(private http: HttpClient) {}

    searchOrder(term: string) {
        this.searchSource.next(term);
    }

    getOrders(): Observable<Order[]> {
        const url = `${environment.apiUrl}/orders`;
        return this.http.get<any[]>(url).pipe(
            map((data) => {
                return data.map((element) => {
                    return this.mapOrder(element);
                });
            })
        );
    }

    getOrder(id: string): Observable<Order> {
        const url = `${environment.apiUrl}/orders/${id}`;
        return this.http.get<any>(url).pipe(
            map((data) => {
                return this.mapOrder(data);
            })
        );
    }

    getOrdersFromDate(fromDate?: Date): Observable<Order[]> {
        const defaultDate = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
        const selectedDate = fromDate ? fromDate : defaultDate;

        const url = `${environment.apiUrl}/orders/fromDate/${selectedDate.toISOString()}`;
        return this.http.get<any[]>(url).pipe(
            map((data) => {
                return data.map((element) => {
                    return this.mapOrder(element);
                });
            })
        );
    }

    getSlots(fromDate?: Date): Observable<Slot[]> {
        const selectDate = fromDate ? fromDate : new Date(Date.now())
        const url = `${environment.apiUrl}/orders/slots/${selectDate.toISOString()}`;
        return this.http.get<Slot[]>(url);
    }

    changeOrderState(id: string, state: string, timeStamp?: string): Observable<Order> {
        const rescheduleTimeStamp = timeStamp ? '/' + timeStamp : '';
        const url = `${environment.apiUrl}/ordini/change-delivery-state/${id}/${state}${rescheduleTimeStamp}`;
        return this.http.post<Order>(url, {});
    }

    mapOrder(element): Order {
        const mappedOrder = new Order();

        mappedOrder.id = element._id;
        mappedOrder.rows = element.righe;
        mappedOrder.total = element.totale;
        mappedOrder.paymentType = element.paymentType;
        mappedOrder.hasError = element.hasError;
        mappedOrder.deliveryMode = element.deliveryMode;
        mappedOrder.orderCounter = element.orderCounter;
        mappedOrder.orderOperationLog = element.orderOperationLog;
        mappedOrder.deliveryCost = element.deliveryCost ? element.deliveryCost : 0;
        mappedOrder.deliveryDiscount = element.deliveryDiscount ? element.deliveryDiscount : 0;
        mappedOrder.note = element.note;
        mappedOrder.deliveryAddress = element.deliveryAddress;
        mappedOrder.deliveryStatus = element.deliveryStatus;
        mappedOrder.deliverySchedule = element.deliverySchedule;
        mappedOrder.deliveryMappedAddress = element.deliveryMappedAddress;
        mappedOrder.isPaid = element.pagato;

        return mappedOrder;
    }
}
