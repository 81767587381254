import { User } from './../../core/models/user';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    windowReference: Window;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem(environment.AuthServiceLocalStorageKey))
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    onLogin(data): Observable<User> {
        const url = `${environment.apiUrl}/users/authenticate`;
        return this.http.post<User>(url, data).pipe(
            map((user) => {
                localStorage.setItem(environment.AuthServiceLocalStorageKey, JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            })
        );
    }

    onLogout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.windowReference = null;
        this.currentUserSubject.next(null);
    }

    getUser() {
        return JSON.parse(localStorage.getItem(environment.AuthServiceLocalStorageKey));
    }

    getPreferences() {
        return JSON.parse(localStorage.getItem(environment.preferencesKey));
    }

    setDatePreference(dateData) {
        let preferences = this.getPreferences();

        preferences = {
            date: dateData,
            order: preferences ? preferences.order : null,
        };

        localStorage.setItem(environment.preferencesKey, JSON.stringify(preferences));
    }

    setOrderPreference(orderData) {
        let preferences = this.getPreferences();

        preferences = {
            date: preferences ? preferences.date : null,
            order: orderData,
        };

        localStorage.setItem(environment.preferencesKey, JSON.stringify(preferences));
    }

    getOrderPreference() {
        const preference = this.getPreferences();
        return preference ? preference.order : true;
    }

    getDatePreference() {
        const preference = this.getPreferences();
        return preference ? new Date(preference.date) : null;
    }

    setWebTapAWindowReference(reference) {
        this.windowReference = reference;
    }

    getWebTapAWindowReference() {
        return this.windowReference;
    }
}
