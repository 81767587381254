import { Injectable } from '@angular/core';
import { CustomersService } from '../customers.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY, forkJoin } from 'rxjs';
import { Customer } from '../models/customer';
import { take, mergeMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomersDetailResolverService {
  constructor(
    private customersService: CustomersService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const id = route.paramMap.get('id');

    return forkJoin([
      this.customersService.getCustomer(id),
      this.customersService.getCustomerCoupons(id),
    ]).pipe(
      take(1),
      map((responses) => {
        if (responses) {
          return {
            data: responses[0],
            coupons: responses[1],
          };
        } else { // id not found
          this.router.navigate(['/customers']);
          return EMPTY;
        }
      })
    )
    /*
    return this.customersService.getCustomer(id).pipe(
      take(1),
      mergeMap(customer => {
        if (customer) {
          return of(customer);
        } else { // id not found
          this.router.navigate(['/customers']);
          return EMPTY;
        }
      })
    );
    */
  }
}
