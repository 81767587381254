import { Injectable } from '@angular/core';
import { CustomersService } from '../customers.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, EMPTY, of } from 'rxjs';
import { Customer } from '../models/customer';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomersListResolverService {

  constructor(
    private customersService: CustomersService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer[]> | Observable<never> {

    return this.customersService.getCustomers().pipe(
      take(1),
      mergeMap(customer => {
        if (customer) {
          return of(customer);
        } else { // id not found
          this.router.navigate(['/customers']);
          return EMPTY;
        }
      })
    );
  }
}
