import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Customer } from './models/customer';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable, Subject } from 'rxjs';
import { CustomerDiscountCode } from './models/customer-discount-code'

@Injectable({
    providedIn: 'root',
})
export class CustomersService {
    private searchSource = new Subject<string>();
    searchTerm$ = this.searchSource.asObservable();

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {}

    searchOrder(term: string) {
        this.searchSource.next(term);
    }

    getCustomers(): Observable<Customer[]> {
        const user = this.authenticationService.getUser();
        const endpoint = user.hasPrivateAppCustomerDB ? 'private-customers' : 'general-customers/all';

        const url = `${environment.apiUrl}/${endpoint}`;

        return this.http.get<Customer[]>(url);
    }

    getCustomer(id: string): Observable<Customer> {
        const url = `${environment.apiUrl}/${this.endpointDefiner()}/${id}`;
        return this.http.get<Customer>(url);
    }

    postCustomer(data: object): Observable<Customer> {
        const url = `${environment.apiUrl}/${this.endpointDefiner()}`;

        return this.http.post<Customer>(url, data);
    }

    putCustomer(id: string, data: object): Observable<Customer> {
        const url = `${environment.apiUrl}/${this.endpointDefiner()}/${id}`;

        return this.http.put<Customer>(url, data);
    }

    deleteCustomer(id: string): Observable<Customer> {
        const url = `${environment.apiUrl}/${this.endpointDefiner()}/${id}`;

        return this.http.delete<Customer>(url);
    }

    redirectToWebApp(customerId: string, hasPrivateDb: string) {
        const url = `${environment.apiUrl}/users/open-webapp-page/${customerId}/${hasPrivateDb}`;

        return this.http.get<any>(url);
    }

    endpointDefiner() {
        const user = this.authenticationService.getUser();

        return user.hasPrivateAppCustomerDB ? 'private-customers' : 'customers';
    }

    makeNewClientVisibleOnCustomerList(customerData) {
        const url = `${environment.apiUrl}/ordini/registration-order`;

        return this.http.post(url, customerData);
    }

    getCustomerCoupons(id: string): Observable<CustomerDiscountCode> {
      const url = `${environment.apiUrl}/coupon/byUser/${id}`;
        return this.http.get<CustomerDiscountCode>(url);
    }
}
