import { AuthenticationModule } from './../authentication/authentication.module';
import { AppModule } from './../../app.module';
import { SharedModule } from './../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './../../shared/angular-material.module';
import { CoreModule } from './../../core/core.module';
import { OrdersRoutingModule } from './orders-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { OrdersToolbarComponent } from './orders-toolbar/orders-toolbar.component';
import { OrdersDetailComponent } from './orders-detail/orders-detail.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    OrdersListComponent,
    OrdersToolbarComponent,
    OrdersDetailComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    AuthenticationModule,

    RouterModule,
    OrdersRoutingModule,

    ScrollingModule
  ]
})
export class OrdersModule { }
