import { IconService } from './core/services/icon.service';
import { User } from './core/models/user';
import { Component, OnInit, HostListener } from '@angular/core';
import { AuthenticationService } from './features/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser: User;

  @HostListener('window:beforeunload')
  clearWebTapWindowReference() {
    const windowReference = this.authService.getWebTapAWindowReference();

    if (windowReference) {
      windowReference.close();
    }
  }

  constructor(
    private authService: AuthenticationService,
    private iconService: IconService,
  ) { }

  ngOnInit(): void {
    this.iconService.registerIcons();
  }
}
