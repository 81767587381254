import { Slot } from './../../models/slot';
import { Order } from './../../models/order';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  order: Order;
  slots: Slot[];
  ordersBySlot: number;
}

@Component({
  selector: 'app-slot-list-dialog',
  templateUrl: './slot-list-dialog.component.html',
  styleUrls: ['./slot-list-dialog.component.scss']
})
export class SlotListDialogComponent implements OnInit {

  selectedOption: string;

  constructor(
    public dialogRef: MatDialogRef<SlotListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.selectedOption = this.data.order.deliverySchedule.slot;
    this.data.slots.forEach(slot => {
      if (slot.slot === this.selectedOption)
        slot.load = -this.data.order.deliverySchedule.load + slot.load
    });
  }

  setSelectedOption(value) {
    const selectedSlot = value.option.value    
    this.selectedOption = selectedSlot;
  }

  showCapacity(load) {
    return `${load} / ${this.data.ordersBySlot}`;
  }

  computeSlotLoad(slot) {        
      return (this.isSelectedRow(slot.slot) ? this.data.order.deliverySchedule.load + slot.load : slot.load)
  }

  isSelectedRow(slot) {
    return this.selectedOption === slot;
  }

  setSlotViewColor(slots, selected) {
    let color = '';
    const slotLoad = selected ? (slots.load + this.data.order.deliverySchedule.load) : slots.load;

    switch (true) {
      case slotLoad <= (this.data.ordersBySlot / 2):
        color = 'green';
        break;
      case slotLoad >= (this.data.ordersBySlot):
        color = 'red';
        break;
    }
    return color;
  }
}
