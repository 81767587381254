<h1 mat-dialog-title>Scegli l'orario di spostamento</h1>
<div mat-dialog-content>
  <mat-selection-list multiple=false (selectionChange)="setSelectedOption($event)">
    <mat-list-option checkboxPosition="before" *ngFor="let slot of data.slots" [value]="slot.slot">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span>{{slot.slot | date:'HH:mm'}}</span>
        <span *ngIf="isSelectedRow(slot.slot);"[ngClass]="setSlotViewColor(slot, true)">
          {{computeSlotLoad(slot)}} / {{data.ordersBySlot}}
        </span>
        <span *ngIf="!isSelectedRow(slot.slot)" [ngClass]="setSlotViewColor(slot, false)">
          {{computeSlotLoad(slot)}} / {{data.ordersBySlot}}
        </span>
      </div>
      </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]=""><mat-icon>close</mat-icon> ANNULLA</button>
  <span class="space"></span>
  <button mat-button color="primary" [mat-dialog-close]="selectedOption" [disabled]="selectedOption == data.order.deliverySchedule.slot"><mat-icon>check</mat-icon> SPOSTA</button>
</div>
