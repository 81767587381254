import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../customers.service';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../models/customer';
import { User } from 'src/app/core/models/user';

@Component({
    selector: 'app-customers-list',
    templateUrl: './customers-list.component.html',
    styleUrls: ['./customers-list.component.scss'],
    styles: ['::content >>> app-customers-list {flex: 1}'],
})
export class CustomersListComponent implements OnInit {
    customers: Customer[];
    customersList: Customer[];
    currentUser: User;

    constructor(private customersService: CustomersService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.getCustomers();
        this.searchTermSubscribe();
    }

    getCustomers() {
        this.route.data.subscribe((data: { customers: Customer[] }) => {
            data.customers = data.customers.sort((a, b) => {
                let returnValue = 0;
                if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
                    returnValue = -1;
                }
                if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
                    returnValue = 1;
                }

                return returnValue;
            });

            this.customers = data.customers;
            this.customersList = data.customers;
        });
    }

    searchTermSubscribe() {
        this.customersService.searchTerm$.subscribe((term) => {
            this.searchCustomer(term);
        });
    }

    searchCustomer(term: string): void {
        this.customersList = this.customers.filter((customer) =>
            JSON.stringify(customer).toLowerCase().search(term.toLowerCase()) !== -1 ? true : false
        );
    }

    completeAddress(addresses) {
        let address;
        if (addresses.length) {
            address = `${addresses[0].address}, ${addresses[0].number} - ${addresses[0].city} (${addresses[0].province})`;

            if (addresses[0].intercomName !== '') {
                address = `${address} - ${addresses[0].intercomName}`;
            }
        } else {
            address = 'Non ha indirizzi registrati';
        }
        return address;
    }

    detailLink(customer) {
        return `/customers/${customer._id}`;
    }
}
