<div fxLayout="column" fxLayoutGap="16px" class="list" ngClass.gt-md="list--resize">
  <mat-card class="zero-padding">
    <mat-nav-list class="zero-padding">
      <a mat-list-item *ngFor="let customer of this.customersList" [routerLink]="['/customers', customer._id]">
        <p matLine>{{customer.lastName | titlecase}}, {{customer.firstName | titlecase}}</p>
        <p matLine>{{completeAddress(customer.addresses) | titlecase}}</p>
        <mat-icon>keyboard_arrow_right</mat-icon>
        <mat-divider></mat-divider>
      </a>
    </mat-nav-list>
  </mat-card>
  <a mat-fab [routerLink]="['/customers/add']"  fxFlexAlign="end" class="fab">
    <mat-icon>add</mat-icon>
  </a>
</div>
