import { Rows } from './rows';
export class Order {
    id: string;
    rows: Rows[];
    total: string;
    paymentType: { how: string; when: string };
    hasError: boolean;
    deliveryMode: string;
    orderCounter: string;
    orderOperationLog: object[];
    deliveryCost: string;
    note: string;
    deliveryAddress: string;
    deliveryStatus: string;
    deliverySchedule: { load: string; slot: string };
    deliveryMappedAddress: {
        firstName: string;
        lastName: string;
        address: string;
        number: string;
        city: string;
        province: string;
        intercomName: string;
        zipCode: string;
        telephone: string;
        country: string;
        email: string;
    };
    deliveryDiscount: string;
    isPaid: boolean;
}
