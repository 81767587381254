import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private http: HttpClient
  ) { }

  getProvinces(state: string): Observable<any[]> {
    const url = `${environment.apiUrl}/cities/provinces/${state}`;
    return this.http.get<any[]>(url);
  }

  getCities(state: string, province: string): Observable<any[]> {
    const url = `${environment.apiUrl}/cities/cities/${state}/${province}`;
    return this.http.get<any[]>(url);
  }
}
