import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers-routing.module';
import { CustomersToolbarComponent } from './customers-toolbar/customers-toolbar.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { CustomersDetailComponent } from './customers-detail/customers-detail.component';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule, CoreModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomersAddressDialogComponent } from './dialogs/customers-address-dialog/customers-address-dialog.component';


@NgModule({
  declarations: [
    CustomersToolbarComponent,
    CustomersListComponent,
    CustomersDetailComponent,
    CustomersAddressDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,

    CustomersRoutingModule
  ]
})
export class CustomersModule { }
