import { AuthenticationService } from './../../features/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (!currentUser) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }

    return currentUser ? true : false;
  }

  canActivateChild() {
    return true;
  }

  canLoad() {
    return true;
  }
}
