import { SlotListDialogComponent } from './../features/orders/orders-dialogs/slot-list-dialog/slot-list-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularMaterialModule } from './angular-material.module';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { PhonePipe } from './pipes/phone.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    PhonePipe,
    ConfirmationDialogComponent,
    SlotListDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FlexLayoutModule
  ],
  exports: [
    PhonePipe,
    ConfirmationDialogComponent,
    SlotListDialogComponent,
  ]
})
export class SharedModule { }
