<h1 mat-dialog-title>Attenzione!</h1>
<div mat-dialog-content>
  <p style="font-size: 16px;">Sei sicuro di voler {{data.message}}?</p>
  <p>{{data.extra}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="false"><mat-icon>close</mat-icon> NO</button>
  <span class="space"></span>
  <button mat-button color="primary" [mat-dialog-close]="true"><mat-icon>check</mat-icon> SI</button>
</div>
