import { AuthenticationService } from './../../../features/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { User } from '../../models/user';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  currentUser: User;
  showMenu = true;
  toolbarTitle = 'Ordini Manager';

  navigationSideMenu = [
    { link: 'orders', label: 'Ordini', icon: 'request_quote' },
    { link: 'customers', label: 'Clienti', icon: 'face' },
  ];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches), shareReplay()
  );

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(result => this.currentUser = result);
    this.routerSubscriber();
  }

  routerSubscriber() {
    this.router.events.subscribe(result => {
      if (result instanceof NavigationEnd) {
        this.toolbarController(result);
      }
    });
  }

  toolbarController(routerData) {
    switch (true) {
      case '/orders' === routerData.url:
        this.showMenu = true;
        this.toolbarTitle = 'Ordini Manager';
        break;
      case routerData.url.includes('/orders/'):
        this.toolbarTitle = 'Dettaglio Ordine';
        this.showMenu = false;
        break;
      case '/customers' === routerData.url:
        this.showMenu = false;
        this.toolbarTitle = 'Clienti';
        break;
      case '/customers/add' === routerData.url:
        this.toolbarTitle = 'Cliente';
        this.showMenu = false;
        break;
      case routerData.url.includes('/customers/'):
        this.toolbarTitle = 'Cliente';
        this.showMenu = false;
        break;
    }
  }

  onLogout() {
    this.authenticationService.onLogout();
    this.router.navigate(['/login']);
  }

  goBack() {
    this.location.back();
  }
}
