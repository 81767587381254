<mat-toolbar class="app-toolbar" [fxShow]="showSearch" [style]="(isHandset$ | async) ? 'max-height: 56px' : 'max-height: 64px'">
  <span class="space"></span>
  <mat-form-field floatLabel="never" class="search-bar" ngClass.gt-md="search-bar--resize" fxFlexAlign="center">
    <input #searchBox matInput placeholder="Ricerca..." (keyup)="searchOrder(searchBox.value)">
    <button mat-button *ngIf="searchBox.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchOrder(searchBox.value='')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <span class="space"></span>
</mat-toolbar>
<router-outlet></router-outlet>

